import React, { useEffect } from "react";
import Nav from "./Nav.tsx";
import MobileNav from "./MobileNav.tsx";

import LogoWhite from "../../assets/images/logo/logo-white.png";
import LogoBlack from "../../assets/images/logo/logo-black.png";
import { navigationItems } from "../../modules/global.ts";
import { useLocation, useNavigate } from "react-router";

import $ from "jquery";
import Swiper from "../../assets/js/plugins/swiper-bundle.min.js";
import AOS from "../../assets/js/plugins/aos.min.js";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const isHeaderBgTransparent: boolean =
    location.pathname === "/" || location.pathname === "/about";

  useEffect(() => {
    $(window).on("scroll", function () {
      const $th = $(this);
      if ($th.scrollTop() > 100) {
        $(".header-sticky").addClass("sticky");
      } else {
        $(".header-sticky").removeClass("sticky");
      }
    });

    $(".mobile-menu-hamburger").on("click", function () {
      $("body").addClass("fix");
      $(".mobile-menu-wrapper").addClass("open");
    });

    $(".offcanvas-btn-close,.offcanvas-overlay").on("click", function () {
      $("body").removeClass("fix");
      $(".mobile-menu-wrapper").removeClass("open");
    });

    new Swiper(".project-details-carousel .swiper-container", {
      loop: true,
      speed: 1150,
      spaceBetween: 30,
      slidesPerView: 1,
      parallax: true,
      effect: "slide",
      navigation: {
        nextEl: ".project-details-carousel .project-details-slider-next",
        prevEl: ".project-details-carousel .project-details-slider-prev",
      },
      pagination: {
        el: ".project-details-carousel .swiper-pagination",
        type: "bullets",
        clickable: "true",
      },
    });

    new Swiper(".tab-pane-carousel-two .swiper-container", {
      loop: true,
      slidesPerView: 4,
      spaceBetween: 1,
      observer: true,
      observeParents: true,

      pagination: {
        el: ".tab-pane-carousel-two .swiper-pagination",
      },

      navigation: {
        nextEl: ".tab-pane-carousel-two .tab-carousel-next",
        prevEl: ".tab-pane-carousel-two .tab-carousel-prev",
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });
    setTimeout(() => {
      AOS.init({
        duration: 1500, // values from 0 to 3000, with step 50ms
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        offset: 0, // offset (in px) from the original trigger point
        once: true,
        easing: "ease",
      });
    }, 1000);
  }, [location.pathname]);

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <div
      className={
        isHeaderBgTransparent
          ? "header header-transparent-bg section-fluid"
          : "header section-fluid border-bottom"
      }
    >
      <div className="header-wrapper">
        <div
          className={
            isHeaderBgTransparent ? "header-sticky" : "header-sticky bg-white"
          }
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-3 col-6">
                <div className="header-logo" onClick={navigateHome}>
                  <img
                    className="fit-image"
                    src={isHeaderBgTransparent ? LogoWhite : LogoBlack}
                    alt="Header Logo"
                  />
                </div>
              </div>

              <div className="col-lg-8 col-md-7 d-none d-md-block">
                <div className="main-menu-language-wrapper">
                  <Nav navItems={navigationItems} />
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-6">
                <div
                  className={
                    isHeaderBgTransparent
                      ? "mobile-menu-hamburger mobile-menu-hamburger-white"
                      : "mobile-menu-hamburger"
                  }
                >
                  <a>
                    <span>Menu</span>
                    <i className="icon ion-android-menu"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-menu-wrapper">
        <div className="offcanvas-overlay"></div>
        <div className="mobile-menu-inner">
          <div className="mobile-menu-inner-top">
            <div className="logo-mobile">
              <img src={LogoBlack} alt="Logo" />
            </div>
            <div className="offcanvas-btn-close">
              <i className="icofont-close-line"></i>
            </div>
          </div>
          <div className="mobile-navigation">
            <MobileNav navItems={navigationItems} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
