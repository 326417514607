import React from "react";

interface EnumNavItem {
  name: string;
  url: string;
}

interface NavProps {
  navItems: EnumNavItem[];
}

const NavItem = ({ url, name }: EnumNavItem) => {
  return (
    <li>
      <a href={url}>{name}</a>
    </li>
  );
};

function MobileNav({ navItems }: NavProps) {
  return (
    <nav>
      <ul className="mobile-menu">
        {navItems.map((navItem: EnumNavItem, index: number) => (
          <NavItem key={index} url={navItem.url} name={navItem.name} />
        ))}
      </ul>
    </nav>
  );
}

export default MobileNav;
