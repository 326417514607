import React, { useContext } from "react";
import LogoBlack from "../../assets/images/logo/logo-black.png";
import { navigationItems } from "../../modules/global.ts";
import { Link, useNavigate } from "react-router-dom";
import { SiteSettingsContextType } from "../../@types/siteSettings.ts";
import { MainContext } from "../../state/state.tsx";

interface EnumNavItem {
  name: string;
  url: string;
}

interface EnumContactInfo {
  address: string | null;
  email: string | null;
  phone: string | null;
}

interface EnumSocialMedia {
  url: string;
  icon: string;
}

function Footer() {
  const currentYear = new Date().getFullYear();
  const { siteSettings } = useContext<SiteSettingsContextType>(MainContext);
  const navigate = useNavigate();

  const NavItem = ({ url, name }: EnumNavItem) => {
    return (
      <li className="mb-3">
        <Link to={url}>{name}</Link>
      </li>
    );
  };

  const ContactInfo = ({ address, email, phone }: EnumContactInfo) => {
    return (
      <div className="contact-info">
        {address} <br />
        <a href={`tel:${phone}`}>{phone}</a> <br />
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    );
  };

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <footer className="section section-padding-top bg-light overflow-hidden">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <div className="footer-logo" onClick={navigateHome}>
              <img src={LogoBlack} alt="Logo Black" />
            </div>
            <ul className="footer-nav mb-n3">
              {navigationItems.map((navItem: EnumNavItem, index: number) => (
                <NavItem key={index} url={navItem.url} name={navItem.name} />
              ))}
            </ul>
            <ContactInfo
              address={siteSettings?.address || null}
              email={siteSettings?.email || null}
              phone={siteSettings?.phone || null}
            />
            <ul className="footer-social-icons social-media-link justify-content-center">
              {siteSettings?.facebook && (
                <li>
                  <a
                    href={siteSettings.facebook}
                    target="_blank"
                    className="icofont-facebook"
                  ></a>
                </li>
              )}
              {siteSettings?.instagram && (
                <li>
                  <a
                    href={siteSettings.instagram}
                    target="_blank"
                    className="icofont-instagram"
                  ></a>
                </li>
              )}
              {siteSettings?.twitter && (
                <li>
                  <a
                    href={siteSettings.twitter}
                    target="_blank"
                    className="icofont-twitter"
                  ></a>
                </li>
              )}
              {siteSettings?.linkedin && (
                <li>
                  <a
                    href={siteSettings.linkedin}
                    target="_blank"
                    className="icofont-linkedin"
                  ></a>
                </li>
              )}
            </ul>
            <div className="copyright">
              © {currentYear} <span>Guler Guler International</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
