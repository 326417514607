import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

interface EnumNavItem {
  name: string;
  url: string;
  currentPathName: string;
}

interface NavProps {
  navItems: EnumNavItem[];
}

const NavItem = ({ url, name, currentPathName }: EnumNavItem) => {
  const activeClass: string = currentPathName === url ? "active" : "";

  return (
    <li>
      <Link className={activeClass} to={url}>
        {name}
      </Link>
    </li>
  );
};

function Nav({ navItems }: NavProps) {
  const location = useLocation();
  const isHeaderBgTransparent: boolean =
    location.pathname === "/" || location.pathname === "/about";
  return (
    <nav
      className={
        isHeaderBgTransparent ? "main-menu main-menu-white" : "main-menu"
      }
    >
      <ul>
        {navItems.map((navItem: EnumNavItem, index: number) => (
          <NavItem
            key={index}
            url={navItem.url}
            name={navItem.name}
            currentPathName={location.pathname}
          />
        ))}
      </ul>
    </nav>
  );
}

export default Nav;
