import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/index.tsx";
import Footer from "./components/Footer/index.tsx";
import ScrollToTop from "./components/ScrollToTop/index.tsx";

const Home = React.lazy(() => import("./screens/Home/index.tsx"));
const Works = React.lazy(() => import("./screens/Works/index.tsx"));
const About = React.lazy(() => import("./screens/About/index.tsx"));
const Contact = React.lazy(() => import("./screens/Contact/index.tsx"));
const WorkDetail = React.lazy(() => import("./screens/WorkDetail/index.tsx"));

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Works />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/project-detail/:id" element={<WorkDetail />} />
      </Routes>
      <ScrollToTop />
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
