export const navigationItems = [
  {
    name: 'Home',
    url: '/'
  },
  {
    name: 'Projects',
    url: '/projects'
  },
  {
    name: 'About',
    url: '/about'
  },
  {
    name: 'Contact',
    url: '/contact'
  }
]