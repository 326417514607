import axios, { AxiosResponse, AxiosError } from 'axios';

// API base URL
const baseURL = 'https://api.gulerguler.com/';

// Axios instance with baseURL
const api = axios.create({
  baseURL,
});

// Axios response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle error
    console.error('API Error:', error.response?.data || 'Unknown error');
    return Promise.reject(error);
  }
);

const sendRequest = {
  get: async (url: string, data: any) => {
    if(data) {
      url += '?';
      for(let key in data) {
        url += `${key}=${data[key]}&`;
      }
      url = url.slice(0, -1);
    }
    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  },

  post: async (url: string, data: any) => {
    try {
      const response = await api.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ... Other methods
};

export default sendRequest;
