import React, { createContext, useReducer } from "react";
import { mainReducer } from "./reducer.ts";
import {
  SiteSettings,
  SiteSettingsContextType,
} from "../@types/siteSettings.ts";

const defaultState = {
  siteSettings: null,
};

export const MainContext = createContext<SiteSettingsContextType>({
  siteSettings: null,
  setSiteSettings: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  const setSiteSettings = (payload: SiteSettings) => {
    dispatch({ type: "SET_SITE_SETTINGS", payload });
  };

  const value = {
    siteSettings: state.siteSettings,
    setSiteSettings,
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
