import React, { useContext, useEffect, useState } from "react";
import AppRouter from "./router.tsx";
import { MainContext } from "./state/state.tsx";
import {
  SiteSettings,
  SiteSettingsContextType,
} from "./@types/siteSettings.ts";
import sendRequest from "./service.ts";
import ScrollToTop from "./components/ScrollToTop/index.tsx";

function App() {
  const [ready, setReady] = useState<boolean>(false);
  const { setSiteSettings } = useContext<SiteSettingsContextType>(MainContext);

  useEffect(() => {
    (async () => {
      const res = await sendRequest.get("/sitesettings", {});
      const parsedResponse: SiteSettings = {
        email: res[0].email,
        phone: res[0].phone,
        address: res[0].address,
        city: res[0].city,
        country: res[0].country,
        location: res[0].map,
        weekdayWorkingHours: res[0].weekdayWorkingHours,
        weekendWorkingHours: res[0].weekendWorkingHours,
        facebook: res[0].facebookUrl,
        instagram: res[0].instagramUrl,
        twitter: res[0].twitterUrl,
        linkedin: res[0].linkedinUrl,
        otherAddresses: res[0].otherOffices,
      };
      setSiteSettings(parsedResponse);
      setReady(true);
    })();
  }, []);

  if (!ready) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <AppRouter />
    </React.Fragment>
  );
}

export default App;
